@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

.blur-background {
  backdrop-filter: blur(8px);
}
.blur-small-background {
  backdrop-filter: blur(0px);
}
